var entity_registration;

function parseStatus(status) {
  if (status == null) return 'Pending';
  return status ? 'Approved' : 'Rejected';
}

function formatTime(timestamp) {
  return moment.utc(timestamp).format('DD-MM-YYYY HH:mm')
}

$(document).on("change", ".agent-limit-select", function () {
  entity_registration = $(this).val();
  // updateBalance();
  $(".new-limit").val(0);
  $(".new-balance").val(0);
  const url = window.location.pathname
  $.ajax({
    type: "GET",
    url: url,
    dataType: "json",
    data: { entity_registration: entity_registration },
    error: function (xhr, status, error) {
      console.error("AJAX Error: " + status + error);
    },
    success: function (data) {
      const formatter = new Intl.NumberFormat("en-US");
      let agentCreditLimit = data["agent_credit_limits"];
      if (agentCreditLimit !== null && agentCreditLimit.length > 0) {
        const approvedLimits = agentCreditLimit.filter(limit => limit.approved !== false);
        approvedLimits.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        let lastAgentCreditLimit = approvedLimits[0];
        let agentLimit = lastAgentCreditLimit.limit;
        let agentBalance = lastAgentCreditLimit.balance;
        $(".agent-current-limit").val(agentLimit);
        $(".agent-current-balance").val(agentBalance);
        $("#agent-limit-history").empty();
        if (lastAgentCreditLimit.approved === null && data.can_edit == true) {
          $(".approve-btn , .reject-btn").removeClass(
            "pointer-events-none opacity-60"
          );
          if (url == "/sub_agent_credit_limits") {
            $(".approve-btn").attr(
              "href",
              `/sub_agent_credit_limits/${lastAgentCreditLimit.id}/approved`
            );
            $(".reject-btn").attr(
              "href",
              `/sub_agent_credit_limits/${lastAgentCreditLimit.id}/rejected`
            );
          } else {
            $(".approve-btn").attr(
              "href",
              `/agent_credit_limits/${lastAgentCreditLimit.id}/approved`
            );
            $(".reject-btn").attr(
              "href",
              `/agent_credit_limits/${lastAgentCreditLimit.id}/rejected`
            );
          }
        } else {
          $(".approve-btn , .reject-btn").addClass(
            "pointer-events-none opacity-60"
          );
        }
        for (let i = 0; i < agentCreditLimit.length; i++) {
          // let agentNewBalance = agentCreditLimit[i]["balance"];

          // let agentOldBalance =
          //   agentCreditLimit.length > 1
          //     ? agentCreditLimit[i + 1]["balance"]
          //     : null;
          // let agentOldLimit =
          //   agentCreditLimit.length > 1
          //     ? agentCreditLimit[i + 1]["limit"]
          //     : null;
          // let agentNewLimit = agentCreditLimit[i]["limit"];
          // let history = agentCreditLimit[i]["audits"];
          // let historyCreated = history[0];
          // let historyUpdated = history.length > 1 ? history[1] : " ";
          // let approvalStatus =
          //   history.length > 1
          //     ? historyUpdated.audited_changes.approved[1]
          //     : ""; data.new_balance
          // let updatedAt = historyUpdated.created_at;
          // updatedAt = new Date(updatedAt);
          // let updatedDate = `${updatedAt.getDate()}-${updatedAt.getMonth()}-${updatedAt.getFullYear()}`;
          // let updatedTime = `${updatedAt.getHours()}:${updatedAt.getMinutes()}:${updatedAt.getSeconds()}`;
          // let updatedBy = historyUpdated.user_id
          //   ? historyUpdated.user.email
          //   : " ";
          // let historyDateTime = historyCreated.created_at;
          // historyDateTime = new Date(historyDateTime);
          // let historyDate = `${historyDateTime.getDate()}-${historyDateTime.getMonth()}-${historyDateTime.getFullYear()}`;
          // let historyTime = `${historyDateTime.getHours()}:${historyDateTime.getMinutes()}:${historyDateTime.getSeconds()}`;
          // let historyUser = historyCreated.user_id
          //   ? historyCreated.user.email
          //   : " ";
          // let agentNewBalance = historyCreated.audited_changes.balance
          //   ? historyCreated.audited_changes.balance
          //   : "";

          const acl = agentCreditLimit[i];
          const firstAudit = acl.audits[0];
          const lastAudit = acl.audits[acl.audits.length - 1];
          const lastAcl = agentCreditLimit[i + 1]
          // console.log(acl, lastAcl);
          const tr = "<tr>" +
            '<td class="text-center status">' +
            parseStatus(acl.approved) +
            "</td>" +
            '<td class="text-center">' +
            ((firstAudit && firstAudit.user && firstAudit.user.email) || '') +
            " (" +
            (firstAudit && formatTime(firstAudit.created_at)) +
            ")" +
            "</td>" +
            '<td class="text-center approved-by">' +
            (lastAudit && (acl.approved != null) ?
              ((lastAudit.user && lastAudit.user.email) || '') + " (" + formatTime(lastAudit.created_at) + ")" :
              '') +
            "</td>" +
            '<td class="text-center">' +
            ((lastAcl && formatter.format(lastAcl.limit)) || '') +
            "</td>" +
            '<td class="text-center">' +
            ((lastAcl && formatter.format(lastAcl.balance)) || '') +
            "</td>" +
            '<td class="text-center">' +
            formatter.format(acl.limit) +
            "</td>" +
            '<td class="text-center">' +
            formatter.format(acl.balance) +
            "</td>" +
            "</tr>"
          // console.log(tr);
          $("#agent-limit-history").append(tr);
        }
      } else if (agentCreditLimit === null || agentCreditLimit.length === 0) {
        $(".agent-current-limit").val(0);
        $(".agent-current-balance").val(0);
        $("#agent-limit-history").empty();
        $(".approve-btn , .reject-btn").addClass(
          "pointer-events-none opacity-60"
        );
      }
    },
  });
});

$(document).ready(function () {
  $(".new-limit").on("blur", function () {
    updateBalance();
  });
});

function updateBalance() {
  const newLimit = $(".new-limit").val().split(',').join('');
  // console.log(newLimit, typeof newLimit);
  const entityRegistration = entity_registration;

  if (newLimit && entityRegistration) {
    $.ajax({
      type: "GET",
      url: window.location.pathname,
      dataType: "json",
      data: { entity_registration: entityRegistration, new_limit: newLimit },
      error: function (xhr, status, error) {
        console.error("AJAX Error: " + status + error);
      },
      success: function (data) {
        const formatter = new Intl.NumberFormat('en-US');
        // $(".new-balance").val(formatter.format(data.new_balance));
        $(".new-balance").val(data.new_balance);
      },
    });
  }
}
